import logo from './logo.svg';
import './App.css';
import hpImage from './assets/homepage.png'
import video from './assets/video1.mp4'
import airportmain from './assets/airport.jpg'
import sc1 from './assets/awarenotes/aware_01.png'
import sc2 from './assets/awarenotes/aware_012.png'
import sc3 from './assets/awarenotes/aware_013.png'
import sc4 from './assets/awarenotes/aware_014.png'
import sc5 from './assets/awarenotes/aware_015.png'
import sc6 from './assets/awarenotes/aware_016.png'
import sc7 from './assets/awarenotes/aware_017.png'
import sc8 from './assets/awarenotes/aware_018.png'
import sc9 from './assets/App-Home-3.jpeg'
import sc10 from './assets/App-Home-4.jpeg'
import sc11 from './assets/App-Home-5.PNG'
import sc12 from './assets/App-Home-6.jpeg'
import bonjour_01 from './assets/iphonesc1.png'
import bonjour_02 from './assets/ihponesc2.png'
import bonjour_03 from './assets/ihponesc3.png'
import pio_01 from './assets/pio1.png'
import pio_02 from './assets/pio2.png'
import pio_03 from './assets/pio3.png'
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdDownloadForOffline } from "react-icons/md";
import { MdAlternateEmail } from "react-icons/md";
import me from './assets/me.jpg'




function App() {
  return (
    <div className="main-container">

          <nav className='nav' style={{position: 'sticky', top: 10, zIndex: 100}}>
            <div>
            <a className='link' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
          <p className='title'>@ deionstfleur</p>

          </a>
            </div>


            <div className='icon-row-nav'>
              {/* <p className='nav-title'>Bio</p> */}

            <a className='link-nav' href="#awarenotes">
              <p className='nav-title-middle'>Projects</p>
            </a>

            <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vQ8lY4hD1fxvUY2EWKaWTwlzi4zmvYxpcQ4xLmxM32ctOiu0WDIJGCXJnzo0LA0z28xeNbV2H_JziO-/pub" className='link-nav'>
              <p className='nav-title'>Resume</p>
            </a>
            </div>
          </nav>
      <div className='bg-gray'>
        <div>

        </div>
        {/* <div>
          <a className='link' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
          <p className='title'>@ deionstfleur</p>

          </a>
        </div> */}


        <div className='main-home-row'>
        <div className='main-left'>

      <div className='me-logo-row'>
          <img src={me} className="me-logo" />
          <p className='main-copy-me'>Hi, I'm</p>
          <p className='main-name'>Deion St.Fleur</p>
      </div>


          {/* <p className='main-copy'>As a dedicated developer, I thrive on the satisfaction of transforming concepts into polished, high-performing digital products.</p> */}


          <div className='main-links-row'>
        <a className='li-link' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
          <p className='li-icon'>LinkedIn</p>
        </a>
        <div className='sep-2'>|</div>
        <a className='li-link' href="https://github.com/deion-stfleur" target="_blank">
          <p className='li-icon'>Github</p>
        </a>
      </div>

    {/* <div className='mx150'>
        <a className='link2' href="mailto:deionstfleur13@gmail.com">
          <div className='msg-btn'>
            <p className='msg-btn-copy'>Leave a message</p>
          </div>
        </a>
    </div> */}


    <div className='top-main-icons-row'>

      <a href="https://github.com/deion-stfleur" target="_blank" className='link2'>
          <div className='tmir-col'>
          <FaGithubSquare className='tmir-icon'/>
          </div>
      </a>

        <a href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank" className='link2'>
          <div className='tmir-col'>
          <FaLinkedin className='tmir-icon'  />
          </div>
        </a>

          {/* <div className='tmir-col'>
          <MdAlternateEmail className='tmir-icon'  />
          </div> */}
    </div>


        </div>

        <div className='main-right'>
          <div>
            <p className='mr-text'>Featured Projects</p>
          </div>


          
          <a href="#Jordans" className='link3'>
          <p className='mr-copy'>Jordans.com</p>
          </a>
          <hr className='mr-line' />

          <a href="#Bonjour" className='link3'>
          <p className='mr-copy'>Bonjour</p>
          </a>
          <hr className='mr-line' />

          <a href="#propertio" className='link3'>
          <p className='mr-copy'>National Property Data Scraper Project</p>
          </a>
          <hr className='mr-line' />

          <a href="#awarenotes" className='link3'>
          <p className='mr-copy'>Awarenotes</p>
          </a>
          <hr className='mr-line' />
        </div>

        </div>



      </div>

{/* 
      <div>
        <p>Current Project</p>
      </div> */}

<div id="awarenotes" className='section-3'>

<div className='inner-section'>
  <p id="show_txt">Current Project:</p>
<p className='s-last-h1'>Awarenotes</p>

<div className='dload_btns'>

 <a href="https://apps.apple.com/us/app/awarenotes/id6563151353" target="_blank" style={{textDecoration: 'none'}}>
  <div className='dload_btn'>
<p className='dload_text'>Try it out <span><i style={{fontSize: '18px'}} className="fa">&#xf179;</i>
</span></p>
  </div>
 </a>
<div style={{height: '100%', backgroundColor: 'transparent', width: '10px'}}></div>
 <a href="https://play.google.com/store/apps/details?id=com.msimeon.awarenotes03" target="_blank" style={{textDecoration: 'none', cursor: 'pointer'}}>
  <div className='dload_btn'>
<p className='dload_text'>Try it out <span><i style={{fontSize: '18px'}} className="fa">&#xf17b;</i>
</span></p>
  </div>
 </a>

</div>

<div>
<p className='role-copy-center'>Awarenotes is a cross-platform app that simplifies the process of capturing and organizing handwritten notes, enhancing productivity for users who need efficient document management. Built with React Native, it delivers a seamless mobile experience, while the backend, powered by Firebase and Node.js, ensures fast and reliable performance. Python is integrated to enable OCR (Optical Character Recognition), transforming handwritten notes into searchable, editable text, and the ability to chat with AI over voice notes.</p>

</div>

<div className='mob-img-scroll-container'>
<img className='mob-img-scroll' src={sc1} alt="cat1" />
  <img className='mob-img-scroll' src={sc2} alt="cat1" />
  <img className='mob-img-scroll' src={sc3} alt="cat1" />
  <img className='mob-img-scroll' src={sc4} alt="cat1" />
  <img className='mob-img-scroll' src={sc5} alt="cat1" />
  <img className='mob-img-scroll' src={sc6} alt="cat1" />
  <img className='mob-img-scroll' src={sc7} alt="cat1" />
  <img className='mob-img-scroll' src={sc8} alt="cat1" />
</div>

<div className='img-wrapper'>
<div className='img-scroller'>
  <div className='scroller'>
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  <img src={sc1} alt="cat1" />
  <img src={sc2} alt="cat1" />
  <img src={sc3} alt="cat1" />
  <img src={sc4} alt="cat1" />
  <img src={sc5} alt="cat1" />
  <img src={sc6} alt="cat1" />
  <img src={sc7} alt="cat1" />
  <img src={sc8} alt="cat1" />
  
      
  </div>

</div>
</div>






  <hr className='hr-line' />

 
</div>

</div>

      <div  className='section-2'>

        <div className='inner-section'>

          

          <div className='s2-row' id="Jordans">
          <div className='s2-col-left'>
          <p className='s2-job-title'>Jordan's Furniture - Frontend Developer</p>
        
        <div className='inner-pos-col'>
          <p className='role-copy'>In my role as a web and mobile developer, I led the global implementation of a cooking compliance banner, resolving functionality issues and ensuring meticulous cookie data collection. Leveraging Sitecore and React, I created reusable components on the Jordan's Furniture website. I developed and deployed web applications, enhancing site accessibility with third-party software integrations.</p>
          <p className='role-copy'>Using JavaScript and jQuery, I crafted custom filters for shopping pages, resulting in increased engagement. Collaborating closely with UX Designers, I contributed to ideation and optimization using Figma. Additionally, I generated new landing pages to complement marketing and email campaigns, embodying a commitment to robust systems and enhanced user experiences.</p>


          <a target="_blank" href="https://www.jordans.com/" className='linkcta'>
            <p>Link to site</p>
          </a>
        </div>
        
          </div>

        <div className='assets-col'>
          <img className='jp-img' alt="Jordan's Homepage" src={hpImage} style={{}} />

          <video className='vid-css' controls autoPlay>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        </div>

          </div>

          <hr className='hr-line' />

          <div  className='section-3'>

          <div className='s2-row' id="Bonjour">
          <div className='s2-col-left'>
          <p className='s2-job-title-black'>Bonjour</p>
         
         <div className='inner-pos-col'>
          <p className='role-copy-black'>Designed as a solution to support immigrants in the Boston area in their quest for affordable and local housing, this mobile platform enables Boston residents and immigrants alike to list available housing and shelter supplies. The app encompasses user features such as registration, housing requests, and options to view nearby leasing opportunities.</p> 
          <p className='role-copy-black'>Housing providers benefit from features like registration and property allocation, all implemented using React Native, Firebase, and the Google Maps API. Throughout development, I focused on creating dynamic components to enhance the app's functionality and seamlessly integrated new screens/pages to optimize the overall user experience.</p>
         
          <a target="_blank" href="https://apps.apple.com/us/app/bonjour/id6463052996?platform=iphone" className='linkcta-black'>
            <p>Link to app</p>
          </a>
         
         </div>
          </div>

     

        <div className='assets-col'>
          <img className='jp-img' alt="Jordan's Homepage" src={airportmain} style={{width: '100%', objectFit: 'cover'}} />

          <div className='s3-row'>
            <img src={bonjour_01} alt="Bonjour Main Image Mobile Screen" />
            <img src={bonjour_02} alt="Bonjour Map Mobile Screen"/>
            <img src={bonjour_03} alt="Bonjour Search Mobile Screen" />
          </div>

       
        </div>

          </div>
          </div>
          <hr className='hr-line' />

        </div>

      </div>

      


      <div className='section-3'>

<div className='inner-section'>

  

  <div className='s2-row' id="propertio" >
  <div className='s2-col-left'>
  <p className='s2-job-title-black'>National Property Scraper Project</p>

<div className='inner-pos-col'>
  <p className='role-copy-black'>The Property Scraper gathers real estate data from online sources, including property listings and details like price, location, and square footage. It covers cities like Boston, MA; San Francisco, CA; and New York City, NY, providing users with insights into the real estate market for informed decision-making. Users can filter properties by price range and access interactive charts to visualize market trends. The project also includes a budget calculation feature to estimate affordability based on income and expenses.</p>
  <p className='role-copy-black'>The Property Scraper offers property data scraping, filtering by price range, and interactive chart visualization. Users can explore dynamic charts showing property price trends and sales volume. The budget calculation feature helps users estimate affordability and recommends properties based on income and expenses.</p>


  <a target="_blank" href="https://propertio-scraper.netlify.app/" className='linkcta-black'>
    <p>Link to site</p>
  </a>
</div>

  </div>

<div className='assets-col'>
  <img className='jp-img' alt="Property Scraper Project" src={pio_01} style={{}} />
  {/* <img className='jp-img' alt="Property Scraper Project Image 2" src={sc5} style={{}} /> */}
  {/* <img className='jp-img' alt="Property Scraper Project Image 3" src={pio_02} style={{}} /> */}

  {/* <video className='vid-css' controls autoPlay>
<source src={video} type="video/mp4" />
Your browser does not support the video tag.
</video> */}
</div>

  </div>


 
</div>

</div>


<hr className='hr-line' />

      <footer>
        <div className='inner-footer'>

    <div className='ft-outer-row'>
      <div className='ft-btn-row'>
        <a className="link2" href="mailto:deionstfleur13@gmail.com" >
          <div className='ft-btn'>
            <p className='ft-btn-copy'> <MdAlternateEmail />&ensp;Contact Me</p>
          </div>

        </a>
        <div className='sep'></div>
          <a className='link2' target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vQ8lY4hD1fxvUY2EWKaWTwlzi4zmvYxpcQ4xLmxM32ctOiu0WDIJGCXJnzo0LA0z28xeNbV2H_JziO-/pub">
          <div className='ft-btn'>
            <p className='ft-btn-copy'> <MdDownloadForOffline />&ensp;Resume</p>
          </div>
          </a>
      </div>


<div className='icon-row'>
      <div className='icon-col-left'>

        <a href="https://github.com/deion-stfleur" className='link2 black-icon' target="_blank">
      <FaGithubSquare className='icon' />
      <p className='ic-text'>Github</p>
        </a>
      </div>

      <div>

        <a className='link2 black-icon' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
      <FaLinkedin className='icon' />
      <p className='ic-tex'>Linkedin</p>
        </a>
      </div>

</div>

    </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
